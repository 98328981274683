import { userAxios } from '../../index'

const reviewSystemList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/rwOfSystem/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemCreate = async data => {
  try {
    return await userAxios.post('insurance/rwOfSystem', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/rwOfSystem/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemDelete = async id => {
  try {
    return await userAxios.delete(`insurance/rwOfSystem/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemPagination = async (perPage, id) => {
  try {
    return await userAxios
      .get(`insurance/rwOfSystem/all/${perPage}?page=${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/rwOfSystem/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const reviewSystemFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/rwOfSystem/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  reviewSystemList,
  reviewSystemCreate,
  reviewSystemUpdate,
  reviewSystemDelete,
  reviewSystemPagination,
  reviewSystemFilter,
  reviewSystemFilterPagination,
}
